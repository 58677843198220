@charset "utf-8";
.content figure { margin-left: 0!important; margin-right: 0!important;  text-align: center; padding: 15px;  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),

;
margin-top: 0px;
box-sizing: content-box;

}

.monkey {margin: 1.5rem!important;}
.hero .hero-body{height: 13.5vh;}

.content {margin-top: 1.5em;}

.card  {margin-top: 0px;}

$spacing-unit: 30px;

.subtitle { margin-top: -.25rem!important;}
p.author  {margin-top: 0.25rem!important; line-height: 1rem;}
.gallery p.title  {margin: 1rem 0 0 0!important;}

$section-book-info-padding:  1.5rem 0;


.book-info-container  {margin: $section-book-info-padding; }
.book-info-container p {line-height:0.725rem!important; }



@import "presets";
@import "bulma";
@import "syntax-highlighting";
@import "line-awesome/scss/line-awesome";
@import "overrides";
